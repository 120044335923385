import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import StoreIcon from '@mui/icons-material/Store';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HelperService from '../services/helper.service';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PrintIcon from '@mui/icons-material/Print';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import RedeemIcon from '@mui/icons-material/Redeem';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';

const Sidebar = () => {

  const navstate = useSelector(state => state.backend.navstate);
  const user = useSelector(state => state.backend.user);
  let redeemuser = HelperService.checkRedeemUser(user);
  let rechargeuser = HelperService.checkRechargeUser(user);
  let adminuser = HelperService.checkAdmin(user);
  let payedcomponents = HelperService.getPayedComponents(user);
  let onlyordersuser = HelperService.checkOnlyOrdersUser(user);

  //payedcomponents.push('rechargeProducts')

  return (
    <div className={'sidebar ' + navstate}>
      <div className="inner">
        <div className="top">
          <span className="logo"></span>
        </div>
        <div className="center">
          <ul>
            <li>
              <NavLink to='/'>
                <DashboardIcon className='icon' /><span>Dashboard</span>
              </NavLink>
            </li>
          </ul>

          {onlyordersuser ?
            <>
              <p className='title'>Statistiken</p>
              <ul>
                <li><NavLink to="/statistiken/bestellungen"><ShoppingCartOutlinedIcon className='icon' /><span>Bestellungen</span></NavLink></li>
              </ul>
              {redeemuser
                ?
                <>
                  <p className='title'>Zusatz</p>
                  <ul>
                    <li><NavLink to="/zusatz/einloeseapp"><AppShortcutIcon className='icon' /><span>Einlöse-App</span></NavLink></li>
                  </ul>
                </>
                :
                ''}
            </>
            :
            <>
              <p className='title'>Einstellungen</p>
              <ul>
                <li><NavLink to="/einstellungen/allgemein"><SettingsSuggestIcon className='icon' /><span>Allgemein</span></NavLink></li>
                <li><NavLink to="/einstellungen/versandoptionen"><LocalShippingOutlinedIcon className='icon' /><span>Versandoptionen</span></NavLink></li>
                <li><NavLink to="/einstellungen/zahlungsanbieter"><AccountBalanceIcon className='icon' /><span>Zahlungsanbieter</span></NavLink></li>
                <li><NavLink to="/einstellungen/bezahlmittel"><PaymentsOutlinedIcon className='icon' /><span>Bezahlmittel</span></NavLink></li>
                {payedcomponents.includes('customTemplates') &&
                  <li><NavLink to="/einstellungen/dokumente"><DocumentScannerOutlinedIcon className='icon' /><span>Dokumente</span></NavLink></li>
                }
                {adminuser && <li><NavLink to="/einstellungen/printvorlagen"><PrintIcon className='icon' /><span>Printvorlagen<span>(nur für Admin sichtbar)</span></span></NavLink></li>}
                <li><NavLink to="/einstellungen/bilder"><PermMediaOutlinedIcon className='icon' /><span>Bilder</span></NavLink></li>
                <li><NavLink to="/einstellungen/texte"><TextSnippetOutlinedIcon className='icon' /><span>Texte</span></NavLink></li>
                {payedcomponents.includes('mailTemplate') &&
                  <li><NavLink to="/einstellungen/emails"><EmailOutlinedIcon className='icon' /><span>E-Mails</span></NavLink></li>
                }
              </ul>
              <p className='title'>Produkte</p>
              <ul>
                <li><NavLink to="/produkte/kategorien"><AppsOutlinedIcon className='icon' /><span>Kategorien</span></NavLink></li>
                <li><NavLink to="/produkte/einzelprodukte"><QrCodeOutlinedIcon className='icon' /><span>Einzelprodukte</span></NavLink></li>
                <li><NavLink to="/produkte/produktpakete"><QrCodeScannerOutlinedIcon className='icon' /><span>Produktpakete</span></NavLink></li>
                <li><NavLink to="/produkte/sortierung"><SwapVertIcon className='icon' /><span>Sortierung</span></NavLink></li>
                <li><NavLink to="/produkte/aktionscodes"><PasswordOutlinedIcon className='icon' /><span>Aktionscodes</span></NavLink></li>
                <li><NavLink to="/produkte/multiaktionscodes"><AutoAwesomeIcon className='icon' /><span>Multiaktionscodes</span></NavLink></li>
              </ul>
              <p className='title'>Statistiken</p>
              <ul>
                <li><NavLink to="/statistiken/bestellungen"><ShoppingCartOutlinedIcon className='icon' /><span>Bestellungen</span></NavLink></li>
                <li><NavLink to="/statistiken/produkte"><DoneAllIcon className='icon' /><span>Verkaufte Produkte</span></NavLink></li>
                {payedcomponents.includes('appointmentBased') && <li><NavLink to="/statistiken/termine"><EventAvailableIcon className='icon' /><span>Verkaufte Termine</span></NavLink></li>}
                <li><NavLink to="/statistiken/auswertung"><TrendingUpIcon className='icon' /><span>Auswertung</span></NavLink></li>
                <li><NavLink to="/statistiken/codeabfrage"><SavedSearchIcon className='icon' /><span>Codeabfrage</span></NavLink></li>
              </ul>
              {payedcomponents.includes('onSiteSales') &&
                <>
                  <p className='title'>Optionen</p>
                  <ul>
                    {payedcomponents.includes('exchangeProduct') &&
                      <>
                        <li><NavLink to="/optionen/umtauschprodukt"><PublishedWithChangesIcon className='icon' /><span>Vorort-Umtauschprodukt</span></NavLink></li>
                        <li><NavLink to="/optionen/warenumtausch"><CurrencyExchangeIcon className='icon' /><span>Warenumtausch</span></NavLink></li>
                      </>
                    }
                    <li><NavLink to="/optionen/vorortverkauf"><StoreIcon className='icon' /><span>Vorort-Verkauf</span></NavLink></li>
                  </ul>
                </>
              }

              {payedcomponents.includes('recharge') &&
                <>
                  <p className='title'>Aufladbare Print-Gutscheine</p>
                  <ul>
                    <li><NavLink to="/aufladbare-printgutscheine/produkte"><QrCodeOutlinedIcon className='icon' /><span>Produkte</span></NavLink></li>
                    <li><NavLink to="/aufladbare-printgutscheine/bestellen"><AddShoppingCartIcon className='icon' /><span>Bestellung aufgeben</span></NavLink></li>
                  </ul>
                </>
              }

              {redeemuser || adminuser
                ?
                <>
                  <p className='title'>Einlösen</p>
                  <ul>
                    <li><NavLink to="/zusatz/einloeseapp"><AppShortcutIcon className='icon' /><span>Einlöse-App</span></NavLink></li>
                    <li><NavLink to="/zusatz/einloesen"><RedeemIcon className='icon' /><span>Code einlösen</span></NavLink></li>
                  </ul>
                </>
                :
                ''}

              {rechargeuser || adminuser
                ?
                <>
                  <p className='title'>Aufladen</p>
                  <ul>
                    <li><NavLink to="/zusatz/aufladen"><ElectricalServicesIcon className='icon' /><span>Code aufladen</span></NavLink></li>
                  </ul>
                </>
                :
                ''}

            </>
          }
        </div>
      </div>
    </div>
  )
}

export default Sidebar